
import { Ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { config } from "@/config";
import {
	key,
	isPaid,
	plan,
	credits,
	isLoggedIn,
	loginCheck,
	account
} from "@/refs/account";
import { showProModal, showLoginModal } from "@/refs/modals";
import { getRelativeDate } from "@/lib/relative-date";
import CopyIcon from "@/components/svgs/CopyIcon.vue";
import Receipt from "../components/svgs/Receipt.vue";
import DiscordIconBlue from "@/components/svgs/DiscordIconBlue.vue";
import infoIconBlue from "@/components/infoIconBlue.vue";
import chevronIcon from "@/components/svgs/ChevronIcon.vue";
import DataObjectIcon from "@/components/svgs/DataObjectIcon.vue";
import ChartIcon from "@/components/svgs/ChartIcon.vue";
import TableIcon from "@/components/svgs/TableIcon.vue";
import NewKey from "@/components/svgs/NewKey.vue";
import { isFree } from "@/refs/account";

import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

import { ref, computed } from "vue";
import { Bar as BarChart } from "vue-chartjs";

const createLink = (url: string) => () => window.open(url, "_blank")?.focus();

const createCopy = (ref: Ref<string | undefined>) => async () => {
	if (ref.value != undefined) {
		// @ts-ignore
		await navigator.permissions.query({ name: "clipboard-write" });
		await navigator.clipboard.writeText(ref.value);
	}
};

type Token = {
	id: string;
	data: { label: string };
	priority: number;
	created_at: string;
	expires_at: string;
	last_used: string | null;
	revoked_at: string | null;
	role: Record<"job" | "admin" | "worker" | "daemon", boolean | undefined>;
};

const isExplorerToken = (token: Token) => {
	const expiresAt = new Date(token.expires_at);
	const createdAt = new Date(token.created_at);

	// explorer token = less than 2 hours between created_at and expires_at
	return expiresAt.getTime() - createdAt.getTime() < 7.2e6;
};

const v2Tokens = ref<Token[] | undefined>(undefined);

export default {
	components: {
		BarChart,
		CopyIcon,
		NewKey,
		chevronIcon,
		Receipt,
		DiscordIconBlue,
		DataObjectIcon,
		infoIconBlue,
		ChartIcon,
		TableIcon
	},
	setup: () => {
		const router = useRouter();

		onMounted(async () => {
			await loginCheck;

			if (isLoggedIn.value === false) {
				router.push("/login");
			}
		});

		const sortedTokens = computed(() => {
			if (v2Tokens.value === undefined) return [];

			return v2Tokens.value.sort((a, b) => {
				if (isExplorerToken(a) && !isExplorerToken(b)) return 1;
				if (!isExplorerToken(a) && isExplorerToken(b)) return -1;

				const aDate = new Date(a.created_at);
				const bDate = new Date(b.created_at);

				return bDate.getTime() - aDate.getTime();
			});
		});

		const v2TokenError = ref<string | undefined>();

		const listV2Tokens = async () => {
			console.log("listV2Tokens() a", key.value, isPaid.value);
			if (key.value === undefined || !isPaid.value) return;
			console.log("listV2Tokens() b");

			const response = await fetch(`${config.arranUrl}/account/token`, {
				method: "GET",
				headers: new Headers({
					"X-Prodia-Key": key.value,
					Accept: "application/json"
				})
			});

			v2Tokens.value = await response.json();

			{
				const response = await fetch(
					`${config.arranUrl}/account/usage/user`,
					{
						method: "GET",
						headers: new Headers({
							"X-Prodia-Key": key.value,
							Accept: "application/json"
						})
					}
				);

				usage.value = await response.json();
			}
		};

		const v2TokenLabel = ref("");
		const newV2Token = ref<string | undefined>();

		const createNewV2Token = async () => {
			if (
				v2TokenLabel.value === "" ||
				key.value === undefined ||
				v2Tokens.value === undefined ||
				!isPaid.value
			)
				return;

			const response = await fetch(`${config.arranUrl}/account/token`, {
				method: "POST",
				headers: new Headers({
					"X-Prodia-Key": key.value,
					Accept: "application/json",
					"Content-Type": "application/json"
				}),
				body: JSON.stringify({
					label: v2TokenLabel.value
				})
			});

			if (response.status < 200 || response.status > 299) {
				v2TokenError.value =
					"Error: You have hit the maximum number of active and revoked tokens.";

				return;
			}

			newV2Token.value = (await response.json()).token;
			v2TokenLabel.value = "";

			await listV2Tokens();
		};

		type UsageDaily = {
			id: string;
			day: string;
			product: string;
			amount: number;
			data: undefined | null | object;
		};

		const usage = ref<UsageDaily[] | undefined>();

		const usageChart = computed(() => {
			const uds = usage.value;

			if (uds === undefined) return undefined;

			const days = [...new Set(uds.map((x) => x.day))].sort();
			const labels = days.map((day) => day.split("T")[0]).slice(-30);

			const productTypes = [...new Set(uds.map((item) => item.product))];

			const datasets = productTypes.map((productType) => {
				return {
					label: productType,
					data: days
						.map(
							(day) =>
								uds.find(
									(item) =>
										item.product === productType &&
										item.day === day
								)?.amount || 0
						)
						.slice(-30)
				};
			});

			return {
				labels,
				datasets
			};
		});

		const revokeV2Token = async (token: Token) => {
			if (key.value === undefined || !isPaid.value) return;

			const response = await fetch(
				`${config.arranUrl}/account/token/${token.id}/revoked`,
				{
					method: "PUT",
					headers: new Headers({
						"X-Prodia-Key": key.value as string,
						Accept: "application/json",
						"Content-Type": "application/json"
					}),
					body: JSON.stringify(true)
				}
			);

			await listV2Tokens();
		};

		onMounted(listV2Tokens);
		watch(account, listV2Tokens);

		const copyNewV2Token = async () => {
			if (newV2Token.value === undefined) return;

			// @ts-ignore
			await navigator.permissions.query({ name: "clipboard-write" });
			await navigator.clipboard.writeText(newV2Token.value);
			newV2Token.value = undefined;
		};

		const isLoading = computed(() => v2Tokens.value === undefined);

		return {
			key,
			copyKey: createCopy(key),
			viewDocs: createLink("https://docs.prodia.com/"),
			tryIt: createLink("https://docs.prodia.com/reference/generate"),
			isPaid,
			showProModal,
			plan,
			v2Tokens,
			sortedTokens,
			isExplorerToken,
			v2TokenLabel,
			newV2Token,
			createNewV2Token,
			revokeV2Token,
			copyNewV2Token,
			v2TokenError,
			getRelativeDate,
			isLoading,
			isFree,
			usage,
			usageChart
		};
	}
};

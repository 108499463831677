
import Navbar from "./components/Navbar.vue";
import Sidebar from "./components/Sidebar.vue";
import LoginModal from "@/components/LoginModal.vue";

export default {
	components: { Navbar, Sidebar, LoginModal }
};

// add a class to the body on resize that stops transitions from occurring
// src: https://css-tricks.com/stop-animations-during-window-resizing/
let resizeTimer: ReturnType<typeof setTimeout>;
window.addEventListener("resize", () => {
	document.body.classList.add("resize-animation-stopper");
	clearTimeout(resizeTimer);
	resizeTimer = setTimeout(() => {
		document.body.classList.remove("resize-animation-stopper");
	}, 400);
});

import {
	createRouter,
	createWebHashHistory,
	createWebHistory,
	RouteRecordRaw
} from "vue-router";
import TermsOfService from "../views/TermsOfService.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import CookiePolicy from "../views/CookiePolicy.vue";
import ThankYouPro from "../views/ThankYouPro.vue";
import API from "../views/API.vue";
import Login from "../views/Login.vue";
import Models from "../views/Models.vue";
import Model from "../views/Model.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "home",
		component: Models
	},
	{
		path: "/models",
		name: "Models",
		component: Models
	},
	{
		path: "/models/:type(.*)",
		name: "Model",
		component: Model
	},
	{
		path: "/explorer",
		redirect: "/models"
	},
	{
		path: "/terms-of-service",
		name: "TermsOfService",
		component: TermsOfService
	},
	{
		path: "/privacy-policy",
		name: "PrivacyPolicy",
		component: PrivacyPolicy
	},
	{
		path: "/cookie-policy",
		name: "CookiePolicy",
		component: CookiePolicy
	},
	{
		path: "/thank-you-pro",
		name: "ThankYouPro",
		component: ThankYouPro
	},
	{
		path: "/thank-you-pro-plus",
		name: "ThankYouProPlus",
		component: ThankYouPro
	},
	{
		path: "/api",
		name: "API",
		component: API
	},
	{
		path: "/login",
		name: "Login",
		component: Login
	},
	{
		path: "/playground",
		redirect: "/"
	}
];

const createHistory =
	typeof process.env.VUE_APP_USE_HTML5_HISTORY === "string"
		? createWebHistory
		: createWebHashHistory;

const router = createRouter({
	history: createHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// If there is a saved position (e.g., back/forward navigation), use it
		if (savedPosition) {
			return savedPosition;
		} else {
			// Otherwise, scroll to the top
			return { top: 0 };
		}
	}
});

export default router;

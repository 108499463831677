import models from "@/models.json";
import processors from "@/processors.json";

export const getModelTitle = (type: string) => {
	const parts = type.split(".");
	// slice off 'inference.' and conditionally slice the version suffix
	const sliceTo = parts.length > 3 ? -2 : -1;
	const title = parts
		.slice(1) // remove 'inference.'
		.slice(0, sliceTo) // remove version suffix based on length
		.map((word) =>
			word
				.replace(/([A-Z])/g, " $1")
				.replace(/([a-zA-Z])(\d)/g, "$1 $2")
				.trim()
				.replace(/^./, (str) => str.toUpperCase())
		)
		.join(" ")
		.replace(/Sdxl/g, "Stable Diffusion XL")
		.replace(/Pro 11/g, "Pro 1.1")
		.replace(/Sd 15/g, "Stable Diffusion 1.5");

	console.log(type, "=>", title);

	return title;
};

export const getModelUrl = (type: string) => {
	const parts = type.split(".");
	const sliceTo = parts.length > 3 ? -2 : -1;
	return `/models/${parts.slice(1, sliceTo).join("/")}`;
};

export const getModelFamily = (type: string) =>
	type.split(".").slice(0, -2).join(".");

export const getModelTime = (type: string) => {
	const processor = processors.find((processor) => type in processor.types);
	if (!processor) return 0;

	// @ts-ignore
	return processor?.types[type]?.eta;
};

export const getModelTags = (type: string) => [
	type.includes("2vid") ? "Video" : "Image"
];

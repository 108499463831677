import { minimatch } from "minimatch";
import processors from "@/processors.json";

const hideGlobs = ["inference.sdxl.mj52.*.*", "inference.flux.schnell.*.v1"];

const matchesHidden = (type: string) =>
	hideGlobs.some((glob) => minimatch(type, glob));

export const adminOnly = ["inference.*.vX"];

const matchesAdmin = (type: string) =>
	adminOnly.some((glob) => minimatch(type, glob));

export const adminJobTypes = ([] as string[])
	.concat(...processors.map((p) => Object.keys(p.types)))
	.filter(
		(type) =>
			type.startsWith("inference.") &&
			(type.includes("sd15") ||
				type.includes("flux") ||
				(type.includes("mochi") && type.endsWith("v1")) ||
				type.includes("sdxl") ||
				type.includes("upscale") ||
				type.includes("recraft.txt2img") ||
				type.includes("inference.kling.txt2vid.v1"))
	)
	.filter((type) => !matchesHidden(type));

export const enabledJobTypes = adminJobTypes.filter(
	(type) => !matchesAdmin(type)
);

export const isFreeEnabled = (type: string) =>
	type.startsWith("inference.flux.schnell") ||
	type.startsWith("inference.flux-fast.schnell");
